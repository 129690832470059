.form {
  width: 100%;
  background: #fff;
  border-radius: 15px;

  padding: 20px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form_container {
  width: 100%;
}

.form_bottomButton {
  display: flex;
  justify-content: center;
  margin-top: 61px;

  input {
    width: 150px;
    height: 30px;
    border: 0;
    border-radius: 10px;
    background: var(--mock-color);
    cursor: pointer;
  }
}

.button__remove {
  margin-left: 24px;
}

.form_icon {
  width: 20px;
  height: 20px;
  cursor: pointer;

  &:hover {
    fill: rgb(110, 107, 107);
  }
}
