.moduleCardHeader {
  color: black;

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  padding: 19px 40px 19px 40px;

  cursor: pointer;
}

.moduleCardHeader__container {
  position: relative;
}

.moduleCardHeader__priority {
  position: absolute;
  height: 37px;
  width: 37px;

  bottom: -22px;
  left: 18px;
  z-index: 1;

  color: var(--main-gradient);
  background: #ffffff;

  box-shadow: 0px 2px 6px rgba(24, 20, 50, 0.13);
  border-radius: 15px;

  font-family: "Exo-2";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;

  text-align: center;
}

.moduleCardHeader_open {
  display: flex;
  width: 100%;
  padding-left: 40px;
  border-radius: 15px;

  background: var(--extra-gradient);
  box-shadow: 0px 2px 6px rgba(24, 20, 50, 0.13);

  cursor: pointer;
}

.moduleCardHeader__content {
  margin: auto 0;
  position: relative;
}

.moduleCardHeader__point {
  position: absolute;
  left: 20px;

  svg {
    display: block;
  }
}

.moduleCardHeader__subtitle {
  font-size: 14px;
  margin: 10px 0 4px 10px;
}

.moduleCardHeader__title {
  font-family: "Exo-2";
  font-weight: 700;
}

.moduleCardHeader__mobileModuleName {
  display: none;
}

.moduleCardHeader__mobile {
  display: flex;
}

.eventView_arrow {
  color: blanchedalmond;
}

@media screen and (max-width: 768px) {
  .moduleCardHeader {
    padding: 4px 18px;
  }

  .moduleCardHeader__point {
    left: 5px;
  }
  // .moduleCardHeader {
  //   min-width: none;
  //   &:first-child {
  //     margin-top: 20px;
  //   }
  // }
  // .moduleCardHeader_open {
  //   display: flex;
  //   width: 100%;
  //   padding-left: 40px;
  //   border-radius: 15px;
  //   background: var(--extra-gradient);
  //   box-shadow: 0px 2px 6px rgba(24, 20, 50, 0.13);
  //   color: #ffffff;

  //   cursor: pointer;
  // }
}
