.educationPeriodDates {
    border: 1px solid #000000;
    margin-top: 35px;
    padding: 46px 0px 42px 91px;
}

.educationPeriodDates__title {
    font-weight: bold;
    font-size: 24px;
}

.educationPeriodDatesView__content {
}

.educationPeriodDatesView__intervals {
}