.equipmentForm {
    display:flex;
    flex-direction: column;
}

.equipmentForm:not(:first-child) {
    margin-top: 21px;
}

.equipmentForm__content {
    display: flex;
    align-items: center;
}

.equipmentForm__name {
    width:355px;
    height:30px;
    margin-right:24px;
    background: #FFF;
    box-shadow: var(--default-shadow);
    border-radius: 10px;
    border: none;
    outline: none;
}

.equipmentForm__name_warn {
    border: 1px solid #FF0000;
}


.equipmentForm__count {
    width:70px;
    height:30px;
    margin-right: 19px;
    border: none;
    outline: none;
    box-shadow: var(--default-shadow);
    border-radius: 10px;
    border: none;
    outline: none;

    &::placeholder {
        font-size: 14px;
    }
}

.equipmentForm__count::-webkit-outer-spin-button,
.equipmentForm__count::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.equipmentForm__count_warn {
    border: 1px solid #FF0000;
}

.equipmentForm__warning {
	position: relative;
    width: 448px;
    margin-top:9px;
    text-align: center;
    color: #FF0000;
	border-radius: .2em;
	background: #fff;
    box-shadow: 0px 0px 1px 1px #FF0000;
    font-size: 12px;
}

.equipmentForm__warning:after {
	position: absolute;
	top: 0;
	left: 99%;

	width: 0;
	height: 0;
	margin-top: -7px;
	margin-left: -18px;

	content: '';

	border: 7px solid transparent;
	border-top: 0;
	border-bottom-color: #fff;

    filter: drop-shadow(0 -0.0625rem 0 #FF0000);
}

.equipmentForm__relocatableSwitcher {
    margin: 25px 0;
}

.equipmentForm__zoneSwitcher {
    // width: 60%;

    margin-bottom: 10px;
}

.equipmentForm__zoneSwitcher_disabled {
    pointer-events: none;
    opacity: 0.4;
}

.equipmentForm__zoneSelect {
    margin-bottom: 15px;
}

.equipmentForm__zoneSelect_disabled {
    pointer-events: none;
    opacity: 0.4;
}