.paramaters {
    margin: 12px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 12px 0;
    border-bottom: 1px solid #ccc;
}

.paramaters__button {
    font-size: 14px;
    align-self: flex-end;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    background: var(--main-gradient);
    padding: 13px 14px;
    color: #fff;
    text-align: center;
}

.paramaters__section_button {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 10px;
}

.paramaters__selection_button {
    font-size: 14px;
    align-self: auto;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    padding: 13px 14px;
    color: #fff;
    margin-bottom: 5px;
    height: max-content;
}

.paramaters__button_inactive {
    font-size: 14px;
    align-self: flex-end;
    border-radius: 10px;
    padding: 13px 14px;
    cursor: pointer;
    position: relative;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: var(--main-gradient);
    filter: grayscale(1);

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10px;
        padding: 3px;
        box-sizing: border-box;
        background: linear-gradient(var(--main-gradient));

        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask-composite: exclude;
    }
}

.paramaters__selectionPointDate {
    margin-bottom: 5px;
}
.paramaters__selectionStatus{
    align-self: flex-start;
    font-size: 12px;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .paramaters {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }
}