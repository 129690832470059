.moduleCards {
  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  &__item {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    border-radius: 15px;
    background: #fff;
    box-shadow: var(--default-shadow);
  }
}

@media screen and (max-width: 980px) {
  .moduleCards__item {
    width: 100%;
  }
}
