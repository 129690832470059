.moduleSkillsListSpecificSkillsWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.moduleSkillsListSpecificSkills {
  width: 49%;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.moduleSkillsListSpecificSkills__item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  background-color: var(--mock-color);
  word-break: break-word;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;

  &:last-child {
    margin-bottom: 0;
  }
}

.moduleSkillsListSpecificSkills__currentSkillName {
  width: 90%;
  padding: 4px 10px;
}

.moduleSkillsListSpecificSkills__currentSkillLevel {
  width: max-content;
  min-width: 25px;
  word-wrap: break-word;
}
