.intervals {
  margin-bottom: 24px;
}

.intervals__empty {
  display:flex;
  justify-content: center;
  margin-top: 20px;
  font-weight: bold;
  font-size: 18px;
}