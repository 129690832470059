.moduleContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px 24px;
}

.moduleContent__header {
  color: #181818;
  font-family: "Exo-2";
  font-weight: 600;
  font-size: 20px;
}

.moduleContent__listOfEvents {
  display: flex;
  gap: 24px;
}

.eventView_hideInMobile {
  display: flex;
  width: 100%;
}

.moduleContent__priorities_title {
  font-family: "Exo-2";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: #c4c4c4;

  cursor: default;
}

.moduleContent__priorities_list {
  display: flex;
  flex-direction: row;

  column-gap: 20px;

  margin: 20px 0 25px;
}

.moduleContent__priorities_priority {
  padding-top: 3px;
  width: 37px;
  height: 37px;

  background: #ffffff;

  cursor: pointer;

  box-shadow: 0px 2px 6px rgba(24, 20, 50, 0.13);
  border-radius: 15px;

  font-family: "Exo-2";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  /* identical to box height, or 29px */

  text-align: center;
}

.moduleContent__priorities_priorityDisable {
  background: var(--disable-gradient);
  color: #ffffff;
  cursor: default;
}
.moduleContent__priorities_priorityActive {
  background: var(--main-gradient);
  color: #ffffff;
  cursor: pointer;
}

.moduleContent__skills {
  display: flex;
  flex-direction: row;
}

.tabs {
  margin-top: 20px;

  position: relative;
}

.tabs__list {
  display: flex;
  flex-wrap: wrap;

  margin-bottom: 15px;
}

.tabs__tab {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 3px 0;
  margin: 0 16px -1px 0;
  cursor: pointer;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #0d0d0d;
}

.tabs__tab_selected {
  cursor: default;

  background: var(--main-gradient);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  border-image-source: var(--main-gradient);
  border-bottom: 2px solid;
  border-image-slice: 1;
  border-width: 2px;
}

.moduleContent__teacher {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 24px;
  break-inside: avoid;
  font-size: 14px;
  box-shadow: var(--default-shadow);
  border-radius: 15px;
}

.moduleContent__teacher_list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 400px;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    border: 1px solid #f6f6f6;
    border-radius: 10px 30px 10px 10px;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 6px;
  }
}

.moduleContent__teacher_roles {
  color: #c4c4c4;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .moduleContent {
    height: auto;
    padding: 0 10px 10px 10px;

    &__skills {
      flex-direction: column;
    }

    &__teacher_list {
      gap: 8px;
      padding: 0;
    }

    &__teacher {
      margin: 0;
    }
  }

  .tabs {
    &__list {
      width: 100%;
      white-space: nowrap;
      flex-wrap: nowrap;
      overflow-x: auto;
      padding: 8px 0;

      li {
        display: inline-block;
      }
    }
  }
}
