.app {
    width: 100%;
    max-width: 1920px;
    display: flex;
    align-items: flex-start;
    gap: 40px;
    line-height: 1.5;
    font-family: 'Manrope';
    position: relative;
    margin: 0 auto auto;
    padding: 0 40px;

    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 40px auto;
    }
}

.pseudolink {
    color: #0b42dc;
    display: inline-flex;
    text-decoration: underline;
    font-size: 16px !important;
    user-select: none;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .app {
        padding: 0 12px;
        gap: 12px;
        flex-direction: column;

        &__content {
            padding: 0;
            gap: 12px;
        }
    }
}
