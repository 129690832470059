.equipmentList {
    display: flex;
    flex-direction: column;
}
.equipmentList_container {
	display: flex;
	flex-direction: row;
    max-width: 347px;
    align-items: flex-end;
    margin-bottom: 24px;
}

.equipmentList_count {
    margin-right: 8px;
    display: flex;
    align-items: center;
}

.equipmentList_input {
    position: relative;

	width: 70px;
	margin-right: 15px;
	padding: 5px;

	border: 1px solid black;
}

.equipmentList_wrapper {
    margin-right: 6px;
    min-width: 245px;
}

.equipmentList_inputFirst{
	width: 360px;
	margin-right: 15px;
	padding: 5px;

	border: 1px solid black;
}

.equipmentList_title {
    font-family: 'Exo-2';
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 16px;
}

.noSuggestions {
    width: calc(344px + 1rem);
    max-height: 143px;
    padding: 0 5px;
    list-style: none;
    text-align: center;
    box-shadow: var(--default-shadow);
    border-radius: 15px;
    color: #000;

    li {
        a {
            color: #6BA3CE;
        }
    }
}

.suggestions {
    width: 360px;
    max-height: 143px;
    padding-left: 0;
    list-style: none;
    text-align: left;
    border-top-width: 0;
    overflow-y: auto;
}

.suggestions li {
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    border: 1px solid #999;
}

.suggestionActive {
    display: flex;
    align-items: center;
    color: black;
    font-weight: 400;
    background: linear-gradient(0deg, rgba(255, 204, 77, 0.5), rgba(255, 204, 77, 0.5)), #FFFFFF;
    cursor: pointer;
}

.suggestions {
    li:hover {
        color: black;
        font-weight: 400;
        background: linear-gradient(0deg, rgba(255, 204, 77, 0.5), rgba(255, 204, 77, 0.5)), #FFFFFF;
        cursor: pointer;
    }
}

.suggestions li:not(:last-of-type) {
 border-bottom: 1px solid #999;
}
