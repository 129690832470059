.popUp {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
    position: fixed;
    padding-left: 100px;
    top: 0;
    background-color: rgba($color: #000000, $alpha: 0.4);
  }
  
  .popUp__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 24px;
    border: 1px solid #000;
    min-width: 700px;
    max-height: 300px;
    background-color: #fff;
  }
  
  .popUp__container {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 80%;
    margin: 120px 50px;
    font-size: 18px;
  }

  .popUp__buttons {
    width: 50%;
    display: flex;
    justify-content: space-around;
    margin-top: 25px;
  }

.blackCrossAddSkillPopUp {
    position: absolute;
    height: 20px;
    width: 20px;
    top: 8px;
    right: 8px;
    cursor: pointer;

    &:hover {
        fill: rgb(110, 107, 107);
    }
}