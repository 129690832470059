.roomTitle {
	margin-bottom: 18px;
}

.formRoom__input {
	font-size: 18px;
    input:required {
        box-shadow: #6BA3CE; // possible to add image
    }
    input:invalid {
        box-shadow: 0 0 5px 1px red;
    }

    input:focus:invalid {
        box-shadow: none;
    }
}