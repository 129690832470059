.formEquip_container {
	display: flex;
	flex-direction: column;
}

.formEquip_header {
    display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 1;
    margin: 0 0 18px 0;
}

.formEquip_list {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
}

.formEquip_listBlock {
    width: 100%;
	display: flex;
	align-items: center;
    justify-content: space-between;
}

.formEquip_nameGroup {
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
    box-shadow: var(--default-shadow);
    background: #FFF;
    border-radius: 15px;
}

.formEquip_btn {
    position: absolute;
    right: -30px;

    display: block;
    align-items: center;

    height: 24px;
    margin: 0;
    padding: 0;

    color: #C4C4C4;
}

.formEquip_img {
    display: block;
    align-items: center;

    width: 22px;
    height: 22px;
}

.formEquip_subtitle {
    font-size: 16px;
}

.formEquip_text {
    font-size: 14px;
}

.roomPage_form {
    display: flex;
}