.educationPeriodParametersView {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(460px, 1fr));
  flex-direction: column;
  gap: 12px;
  row-gap: 24px;
}

.educationPeriodParametersView__schedules {
  display: flex;
  flex-direction: column;
  gap: 12px;
  // flex-direction: row;
}

.educationPeriodParametersView__title {
  font-weight: bold;
}

.educationPeriodParametersView__buttonPanel {
  align-self: flex-end;
  margin-right: 91px;
}

.educationPeriodParametersView__saveButton {

}

.educationPeriodParametersView__workload {
  display: flex;
  flex-direction: column;
  gap: 12px;
  // margin-top: 71px;
}

.educationPeriodParametersView__paragraph {
  // margin-bottom: 21px;
  // margin-top: 6px;
}

.educationPeriodParametersView__editButton {
  box-sizing: border-box;
  height: 37px;
  width: 179px;
  margin-left: 25px;
  background-color: transparent;
  border: 3px solid var(--mock-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
