.equipmentList {
    display: flex;
    flex-direction: column;
    // flex-direction: row;
    // flex-wrap: wrap;

    &::after {
        content: "";
        flex: auto;
    }

    &__title {
        width:100%;
        margin-bottom: 20px;
        text-align: left;
        letter-spacing: 0em;
        font-size: 24px;
        font-weight: 700;
        font-style: normal;
    }

    &__cards {
        column-count: 3;
        column-gap: 18px;
    }

    &__card {
        width: 100%;
        display: inline-flex;
        box-shadow: var(--default-shadow);
        border-radius: 15px;
        margin-bottom: 18px;
        background-color: #fff;
        padding: 18px 12px 18px 12px;
        gap: 12px;
    }

    &__cardLetter{
        display: flex;
        font-family: 'Exo-2';
        font-weight: 700;
        font-size: 24px;
        margin: -10px 0 0 0;
    }

    &__cardItems{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &__cardItem {
        display: flex;
        align-items: flex-start;
        gap: 12px;
    }

    &__cardTextContainer {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &__cardItemName {
        font-size: 14px;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    &__cardItemDelete {
        margin: 6px 0;
        padding: 0;
        flex-shrink: 0;
    }

    &__cardItemCount {
        max-width: 48px;
        font-family: 'Exo-2';
        font-weight: 600;
        font-size: 16px;
        word-wrap: break-word;
    }
}

.equipmentGroupDialog {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .4);

    &__container {
        position: relative;
        background: #FFF;
        box-shadow: var(--default-shadow);
        border-radius: 15px;
        min-width: 786px;
        background-color: #fff;
    }

    &__content {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 21px 60px 26px;
        font-size: 18px;
    }

    &__inputName {
        width: 360px;
        height: 30px;
        margin-right: 24px;
        margin-bottom: 30px;
    }

    &__inputCount {
        width: 70px;
        height: 30px;
    }

    &__icon {
        position: absolute;
        top: 22px;
        right: 22px;
        cursor: pointer;
    }
}
