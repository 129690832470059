.moduleSkillsBlock__text {
  margin-bottom: 4px;

  font-weight: 700;
}

.moduleSkillsBlock__item {
  margin-bottom: 8px;
  padding: 4px 12px;

  background-color: var(--mock-color);

  &:last-child {
    margin-bottom: 0;
  }
}

.moduleSkillsBlock__item_list {
  margin-bottom: 16px;
}
