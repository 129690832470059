.moduleSkillsListSwitchParams {
  display: flex;
  margin-bottom: 12px;
}

.moduleSkillsListSwitchParams__item {
  width: 50%;
  text-align: center;
  font-size: 14px;
}
