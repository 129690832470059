.eventList {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-width: 360px;
  max-height: 600px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-shrink: 0;
}

.eventList::-webkit-scrollbar {
  width: 5px;
}

.eventList::-webkit-scrollbar-track {
  border: 1px solid #f6f6f6;
  border-radius: 15px;
}

.eventList::-webkit-scrollbar-thumb {
  background: rgba(177, 177, 177, 0.2);
  border-radius: 6px;
}

.student__content {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  flex-shrink: 1;
}

.eventList__eventItem_mobile {
  display: none;
}

@media screen and (max-width: 980px) {
  .eventList {
    width: 100%;
    max-height: 100%;
    text-align: left;
    margin: 0 auto;
  }

  .student__content {
    flex-grow: 1;
    margin-top: 78px;
    margin-left: 36px;
  }

  .eventList__eventItem_mobile {
    display: block;
  }
}

@media screen and (max-width: 980px) {
  .eventList {
    overflow: auto;
  }
}
