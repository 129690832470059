.moduleCardInfo {
  display: flex;
  justify-content: space-between;
}

.moduleCardInfo__progressInfo {
  width: 60%;
  margin-right: 67px;
}

.moduleCardInfo__passedEvents {
  font-weight: 700;
  color: black;
}

.moduleCardInfo__progressBarTitle {
  display: flex;
  justify-content: space-between;
  font-family: "Exo-2";
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #181818;
}

.moduleCardInfo__progressBarContainer {
  margin: 7px 0 24px 0;
}

.moduleCardInfo__progressEvent {
  display: flex;
  justify-content: space-between;
  font-family: "Exo-2";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000;
  &:nth-child(2n) {
    margin: 4px 0;
  }
}

.moduleCardInfo__progressEvent p:last-child {
  color: #c4c4c4;
}

.moduleCardInfo__progressEvent p:last-child b {
  color: black;
}

.moduleCardInfo__description {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .moduleCardInfo {
    flex-direction: column;
    gap: 12px;
  }

  .moduleCardInfo__progressInfo {
    width: 100%;
    margin: 0;
  }
}
