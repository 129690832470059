.educationPeriodParametersEdit {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(460px, 1fr));
    gap: 24px;
    row-gap: 24px;
}

.educationPeriodParametersEdit__schedules {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.educationPeriodParametersEdit__title {
    font-weight: bold;
}

.educationPeriodParametersEdit__buttonPanel {
    display: flex;
    gap: 12px;
}

.educationPeriodParametersEdit__saveButton {

}

.educationPeriodParametersEdit__cancelButton {

}

.educationPeriodParametersEdit__workload {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.educationPeriodParametersEdit__paragraph {

}