.educationPeriodDatesEdit__intervalSelectionTitle {
    margin-top: 60px;
    margin-bottom: 28px;
}
.educationPeriodDatesEdit__buttonPanel {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.educationPeriodDatesEdit__saveButton {
    margin-left: 25px;
}