.educationPeriodParameters {
    background: #fff;
    box-shadow: var(--default-shadow);
    border-radius: 15px;
    padding: 24px 40px;
}

.educationPeriodParameters__title {
    font-weight: bold;
    font-size: 24px;
    margin: 0 0 24px 0;
}

.educationPeriodParametersView {
    display: flex;
    flex-direction: column;
}

.educationPeriodParametersView__schedules {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.educationPeriodParametersView__title {
    font-weight: bold;
}

.educationPeriodParametersView__buttonPanel {
    align-self: flex-end;
    margin-right: 91px;
}

.educationPeriodParametersView__saveButton {
    margin: 12px 0;
}

.educationPeriodParametersView__workload {
    margin-top: 71px;
}

.educationPeriodParametersView__paragraph {
    margin-bottom: 21px;
    margin-top: 6px;
}

@media screen and (max-width: 768px) {
    .educationPeriodParameters {
        padding: 12px;

        &__title {
            font-size: 18px;
        }
    }
}
