.moduleTabs {
    margin-bottom: 20px;

    &__list {
        display: flex;
        border-top: 1px solid #ccc;
    }

    &__item {
        cursor: pointer;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;

        &:last-child {
            border-right: none;
        }
    }

    &__item_active {
        border-bottom: none;
    }

    &__text {
        padding: 8px 12px;
        font-size: 12px;
        font-weight: 500;
    }
}

