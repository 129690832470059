.eventView {
  width: 100%;
  display: flex;
  // margin-left: 14%;
  margin-right: 5%;
}

.eventView_verticalCenter {
  display: flex;
  justify-content: center;
}

.eventView__plug {
  display: flex;
  width: 50%;
  margin-top: 25%;
  text-align: center;
  justify-content: center;
}

.eventView__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.eventTypeName {
  font-size: 14px;
  margin-bottom: 10px;
  margin-right: 5px;
}

.eventName {
  font-family: "Exo-2";
  font-weight: 700;
  font-size: 22px;
  color: #0d0d0d;
  margin-bottom: 8px;
}

.eventView__duration {
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
}

.eventView__duration span {
  text-align: left;
}

.eventView__title {
  display: flex;
  flex-direction: row;
}

.eventView__attendance {
  display: flex;
  flex-direction: row;
}

.eventView__attendanceSign {
  background: var(--mock-color);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  align-self: flex-start;
}

@media screen and (max-width: 980px) {
  .eventView {
    width: 100%;
    margin: 0 8px 25px 0;
  }

  .eventView__plug {
    display: none;
  }

  .eventView__content {
    width: 100%;
    display: flex;
    margin-left: 16px;
  }
}

@media screen and (max-width: 500px) {
  .eventView {
    margin: 0;
    margin-bottom: 25px;
  }
  .eventName {
    font-size: 20px;
    line-height: 22px;
  }
}
