.workingIntervals {
    padding: 0px 20px 12px 20px;
}

.workingIntervals__title {
    padding: 10px 0px 10px 0px;
    cursor: default;
}

.workingIntervals__interval {
    border-top:1px solid rgba(196, 196, 196, 1);
    padding: 12px 0px 12px 0px;
}

.workingIntervals__addInterval {
    display: flex;
    border-top:1px solid rgba(196, 196, 196, 1);
    padding: 12px 0px 12px 0px;
    cursor: pointer;
    margin-bottom: 90px;
}

.workingIntervals__addIntervalIcon {
    width: 19px;
    height: 19px;
    margin-left: 37px;
    cursor: pointer;
}