.calendar__popperSpecialDays {
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__year-text--in-selecting-range {
    color: #fff;
    background: var(--extra-gradient);
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--today,
  .react-datepicker__day--in-range {
    color: #fff;
    background: var(--main-gradient);
  }
  margin-top: -20px;
}

.calendar__wrapperSpecialDays {
  .react-datepicker__close-icon::after {
    background-color: var(--mock-color);
    color: #000;
    font-size: 14px;
  }
}

.calendar__input {
  width: 216px;
  height: 30px;
  padding-left: 12px;
}

.react-datepicker__input-container {
  background: #fff;
  box-shadow: var(--default-shadow);
  border-radius: 10px;
  width: 254px;
  height: 40px;
  margin-bottom: 21px;
}

.react-datepicker__input-container input {
  border: none;
  margin-top: 5px;
  font-size: 14px;
}

.react-datepicker-ignore-onclickoutside {
  border: none;
  outline: none;
}
