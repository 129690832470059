.eventItem {
  width: 100%;
  position: relative;
  font-size: 12px;
  border-radius: 13px;
  padding: 12px;
  background: #ffffff;
  box-shadow: var(--default-shadow);
  cursor: pointer;
}

.eventItem_status_active::before {
  content: "";
  position: absolute;
  top: -3px;
  bottom: -3px;
  left: -3px;
  right: -3px;
  border-radius: 15px;
  background: var(--main-gradient);
  z-index: -1;
}

.eventItem_status_passed {
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    background: #fff;
    opacity: 0.7;
    border-radius: 15px;
  }
}

.eventItem__title {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.eventItem__description {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.eventItem__attendanceSign {
  position: absolute;
  top: 5px;
  right: 8px;
  background: #ffc452;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  z-index: 2;
}

// @media screen and (max-width: 980px) {
//   .eventItem {
//     display: block;
//     width: 98%;
//     padding: 11px;
//     font-size: 14px;

//     background: #ffffff;
//     border: 2px solid transparent;
//     background-clip: padding-box;
//     box-shadow: 0 2px 6px rgba(24, 20, 50, 0.13);
//     border-radius: 15px;
//     margin: 16px 0;
//     cursor: pointer;
//   }

//   .eventItem_status_active::before {
//     content: "";
//     position: absolute;
//     top: -2px;
//     bottom: -2px;
//     left: -2px;
//     right: -2px;
//     border-radius: 15px;
//     background: var(--main-gradient);
//     z-index: -1;
//   }
// }

// @media screen and (max-width: 425px) {
//   .eventItem {
//     position: relative;
//     padding: 7px 16px;
//     font-size: 14px;
//     background: #ffffff;
//     border: 2px solid transparent;
//     background-clip: padding-box;
//     box-shadow: 0 2px 6px rgba(24, 20, 50, 0.13);
//     border-radius: 15px;
//     margin: 16px 0;
//     cursor: pointer;
//   }

//   .eventItem_status_active::before {
//     content: "";
//     position: absolute;
//     top: -2px;
//     bottom: -2px;
//     left: -2px;
//     right: -2px;
//     border-radius: 15px;
//     background: var(--main-gradient);
//     z-index: -1;
//   }
// }
