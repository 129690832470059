.periodTitleEdit {
    display: flex;
    flex-direction: column;
}

.periodTitleEdit__box {
    width:360px;
    height:30px;
    padding: 2px 5px 12px 5px;
    border: 1px solid #000000;
}

.periodTitleEdit__input {
    width:360px;
    height:26px;
    background-color: transparent;
    border:none;
    outline: none;
    text-align: left;
}

.base-time-interval-instance {
    &:not(:last-of-type) {
        border-bottom: 1px solid #ccc;
        margin-bottom: 12px;
    }
}

.base-time-interval-instance__title {
    font-family: 'Exo-2';
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: -0.05em;
    margin-bottom: 30px;
}

.base-time-interval-instance__subtitle {
    font-family: 'Manrope';
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
}