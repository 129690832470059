.timeScheduleSelector__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    max-width: 500px;
    height: 32px;
    border: 1px solid #ccc;
    padding: 0 12px;
    color: #ccc;
}

.timeScheduleSelector__triangle {
    width: 7px;
    height: 16px;
}

.timeScheduleSelector__triangle_active {
    transform: rotate(0.25turn);
}
