.equipment {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 18px 48px;
    background: #fff;
    box-shadow: var(--default-shadow);
    border-radius: 15px;
}

.equipment__item {
    margin-bottom: 21px;
}

.equipment__title {
    width: 100%;
    margin-bottom: 24px;
    text-align: left;
    font-size: 24px;
    font-weight: 700;
}

.equipment__nameEquipment {
    margin-bottom: 3px;
    text-align: left;
    font-family: "Exo-2";
    font-weight: 600;
}

.equipment__more {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 195px;
    margin-bottom: 48px;
    font-size: 18px;
}

.equipment__moreIcon {
    width: 22px;
    height: 22px;
}

.equipment__moreLabel {
    margin-left: 12px;
}

.equipment__button {
    align-self: center;
    margin-right: 66px;
}
