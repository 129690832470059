.avtorScheduleDownload {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0;
}

@media screen and (max-width: 768px) {
  .avtorScheduleDownload {
    flex-direction: column;
    gap: 12px;
  }
}