.timePicker {
    position: absolute;
    width: 100%;
    max-width: 500px;
    background: #FFFFFF;
    border: 1px solid #ccc;
    border-top: 0px;
    display: flex;
    flex-direction: row;
    z-index: 2;
}

.timePicker__daysNavigation {
    width:100px;
    border-right: 1px solid #ccc;
}

.timePicker__saveButton {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 12px;
    bottom: 12px;
    cursor: pointer;
}