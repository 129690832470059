.workLoad {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.workLoad__title {
    font-weight: bold;
    font-size: 18px;
}

.workLoad__workLoadAmount {
    display: flex;
}

.workLoad__box {
    padding: 2px 5px 2px 5px;
    border: 1px solid #000000;
}

.workLoad__input {
    width:38px;
    height:21px;
    background-color: transparent;
    border:none;
    outline: none;
    text-align: center;
}

.workLoad__workLoadAmountDescription {

}