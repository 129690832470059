.weekDayIntervals__day {
    display: flex;
    flex-direction: row;
}

.weekDayIntervals__day_disable {
    color: #C4C4C4;
}

.weekDayIntervals__dayTitle {
    margin-right: 5px;
}

.weekDayIntervals__intervals {
    display: flex;
    font-weight: bold;
}