.zoneSelector {
    display: flex;
    flex-direction: column;
}

.zoneSelector__label {
    margin: 0 0 8px 0;
}

.zoneSelector__label_disabled {
    color: #C4C4C4;
}

.zoneSelector__select {
    width: 333px;
    height: 30px;
    font-size: 14px;
    outline: none;
    border: none;
    background-color: #fff;
    box-shadow: var(--default-shadow);
    border-radius: 10px;
}

.zoneSelector__defaultOption {
    color: #C4C4C4;
}