.calendar__popperEducactionPeriod {
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__year-text--in-selecting-range {
        background: #afd6f0;
        color: #000;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--today,
    .react-datepicker__day--in-range {
        background: var(--mock-color);
        color: #000;
    }

    z-index: 2;
    margin-top: -20px;
}

.calendar__wrapperEducactionPeriod {
    .react-datepicker__close-icon::after {
        background-color: var(--mock-color);
        color: #000;
        font-size: 14px;
    }
}

.calendar__input {
    width: 216px;
    height: 30px;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    border-left: none;
    border-right: 1px solid #000;
    padding-left: 12px;
    font-size: 14px;

    &::placeholder {
        font-size: 14px;
        color: #c4c4c4;
    }
}

.react-datepicker__input-container {
    width: 100%;
    // height: 30px;
    margin-bottom: 21px;
}

.react-datepicker__input-container input {
    border: none;
    margin-top: 5px;
}

.react-datepicker-ignore-onclickoutside {
    border: none;
    outline: none;
}
