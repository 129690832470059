.daysNavigation__title {
  border-bottom: 1px solid #000000;
  padding: 10px 0px 12px 16px;
}

.daysNavigation__dayButton {
  box-sizing: border-box;
  padding: 11px 0px 12px 17px;
  cursor: pointer;
}

.daysNavigation__dayButton:hover {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  padding: 10px 0px 11px 17px;
}

.daysNavigation__dayButton:last-child:hover {
  border-bottom: 1px solid transparent;
}

.daysNavigation__dayButton:nth-child(2):hover {
  border-top: 1px solid transparent;
}

.daysNavigation__dayButton_holiday {
  color: #c4c4c4;
}

.daysNavigation__dayButton_current {
  background-color: var(--mock-color);
  padding: 10px 0px 11px 17px;
  //border-top: 1px solid #000000;
  //border-bottom: 1px solid #000000;
}

.daysNavigation__dayButton_current:hover {
  border: none;
  padding: 10px 0px 11px 17px;
}
.daysNavigation__dayButton_current:nth-child(2):hover {
  border: none;
  padding: 10px 0px 11px 17px;
}
