.collapseExpand {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  margin-top: 24px;
  padding: 0;
}

.collapseExpand__content {
  display: none;
}

.collapseExpand__content_show {
  display: block;
  width: 100%;
}

.collapseExpand__buttonAndTitle {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.collapseExpand__icon {
    cursor: pointer;
    width: 16px;
    height: 8px;
    fill: url('#arrow-linear-gradient');
    transform: rotate(180deg);
    margin-right: 7px;
    fill: #000000;
}

.collapseExpand__name {
  font-family: 'Exo-2';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
}

.collapseExpand__icon_turnOver {
  transform: rotate(0deg);
}

@media screen and (max-width: 980px) {
  .collapseExpand__name {
    font-size: 14px;
    width: 100%;
    margin-right: 15px;
  }
}
