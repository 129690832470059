.moduleSpecificMeeting__title {
  display: flex;
  cursor: pointer;
}

.moduleSpecificMeeting__name {
  max-width: 256px;
  word-break: break-word;
  display: flex;
  margin-right: 10%;
}

// .moduleSpecificMeeting__iconContainer {
//     transform: rotate(0deg);
//     transition: transform .3s;
// }

// .moduleSpecificMeeting__iconContainer_rotate {
//     transform-origin: -50% -50%;
//     transform: rotate(90deg);
// }

.moduleSpecificMeeting__icon {
  height: 12px;
  width: 6px;
}

.moduleSpecificMeeting__options {
  overflow: hidden;
}

.moduleSpecificMeeting__options_height_null {
  height: 0;
}

.moduleSpecificMeeting__formatAndDuration {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.moduleSpecificMeeting__format {
  display: flex;
  flex-direction: column;
  max-width: 121px;
  word-break: break-word;
}

.moduleSpecificMeeting__duration {
  display: flex;
  flex-direction: column;
}

.moduleSpecificMeeting__skillsList {
  margin: 24px 0 0;
}