.infoCard {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 945px;
  padding: 24px 40px 24px 66px;
  margin-top: 25px;
  background-color: #f2f2f2;
  border-radius: 15px;
  font-size: 14px;

  color: #717171;

  cursor: default;
}

.infoCard__whithArrow {
  cursor: pointer;
}

.infoCard__alertIcon {
  margin-top: -40px;
  margin-left: -76px;
}

.infocard__title {
  font-family: "Exo-2";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.infoCard__text {
  margin-top: 10px;
  margin-left: 10px;
}

.infoCard__arrow {
  position: absolute;
  right: 40px;
}

.choicePoint {
  display: flex;
  align-items: center;
  gap: 18px;

  margin: 24px 0;

  cursor: pointer;

  &:last-child {
    margin-bottom: 150px;
  }
}

.choicePoint__title {
  font-family: "Exo-2";
  font-weight: 600;
  font-size: 18px;
}

.choicePoint__RemainingPriorities_text {
  font-size: 14px;
  color: #000000;
  padding: 8px 0 0 0;
}
