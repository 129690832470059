.educationSubspaceTypeForm {
    padding: 40px 50px;
    border-radius: 15px;
    background: #fff;
    box-shadow: var(--default-shadow);
    margin: 24px 0;

    ul {
        display: flex;
        flex-direction: column;
        margin: 12px 0;

        li {
            display: inline-flex;
            align-items: center;
            gap: 12px;

            svg {
                width: 18px;
                height: 18px;
                flex-shrink: 0;
            }
        }
    }
}

// .educationSubspaceTypeForm__border {
// width: 786px;
// margin-bottom: 20px;

// border: 1px solid #000;
// }

// .educationSubspaceTypeForm__container {
//   // margin: 21px 10px 26px 60px;
// }

// .educationSubspaceTypeForm__head {
//   padding-top: 30px;
//   padding-bottom: 20px;

//   font-size: 24px;
//   font-weight: 700;
// }

.educationSubspaceTypeForm__label {
    display: flex;
    flex-direction: column;
    margin: 12px 0;
    gap: 12px;
}

// .educationSubspaceTypeForm__input {
//   height: 30px;
//   margin-top: 5px;

//   border: 1px solid black;
// }

.educationSubspaceTypeForm__header {
    display: flex;
    align-items: center;
    gap: 12px;
}

.educationSubspaceTypeForm__containerTimeInterval {
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 12px 0;
}

// .educationSubspaceTypeForm__selector {
//   width: 247px;
//   height: 30px;
//   margin-top: 6px;
//   margin-right: 10px;
//   margin-bottom: 5px;
// }

// .educationSubspaceTypeForm__IconContainer {
//   display: flex;
//   align-items: flex-start;

//   margin-top: 7px;

//   cursor: pointer;
// }

.educationSubspace__IconAddSubspaceContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 12px;
}

// .educationSubspace__IconAddSubspaceContainer_displayNone {
//   display: none;
// }

// .educationSubspaceTypeForm__listTimeInterval {
//   position: relative;

//   display: flex;
//   align-items: center;

//   width: 190px;
//   margin-bottom: 6px;
//   padding: 2px 0 2px 5px;

//   background: var(--mock-color);
// }

// .educationSubspaceTypeForm__listTimeInterval_name {
//   padding: 3px;
//   padding-right: 10px;

//   font-size: 14px;
// }

.educationSubspace__textAddSubspace {
    display: flex;
    gap: 12px;
    cursor: pointer;
}

.educationSubspaceTypeForm__buttonCreate {
    display: flex;
    gap: 12px;
    margin: 12px 0;
    font-weight: 600;
}

.educationSubspaceForm {
    padding: 40px 50px;
    background-color: #fff;
    border-radius: 15px;
    margin: 24px 0;
    box-shadow: var(--default-shadow);
}

// .educationSpace__switcherBlock {
//   margin-left: 20%;
//   padding-top: 20px;
// }

// .educationSubspaceForm__container {
//   margin: 21px 60px 26px;
// }

// .educationSpaceEdit_container {
//   margin: 21px 10px 26px 100px;
// }

.educationSubspaceForm__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    font-size: 18px;
    font-weight: 700;
}

.educationSubspaceForm__label {
    display: flex;
    flex-direction: column;

    width: 360px;
    margin-bottom: 17px;
}

.educationSubspaceForm__input {
    height: 30px;
    margin-top: 5px;

    border: 1px solid black;
}

.educationSubspaceForm__ButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    margin-right: 42px;
}

.educationSubspaceEdit__formContainer {
    display: flex;
    flex-direction: column;
}

.educationSubspaceCard__container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 12px;
}

.educationSubspaceCard__subspaceName {
    cursor: pointer;
    text-decoration: underline;
    word-break: break-all;

    font-size: 18px;
    font-weight: 700;
}

.educationSubspaceCard__subspaceAdministrator {
    font-weight: 500;
}

.educationSubspaceFormEdit__crossContainer {
    display: flex;
    justify-content: space-between;
}

.educationSubspaceForm__crossContainer {
    display: flex;
    justify-content: space-between;
}

.timeMesurament__hidden {
    height: 0;
    overflow: hidden;
}

.timeMesurament__show {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.timeMesurament__text {
    padding-left: 10px;
}

.educationSpaceForm {
    margin: 12px 0;
    padding: 40px 50px;
    background: #fff;
    border-radius: 15px;
    box-shadow: var(--default-shadow);
}

.educationSpaceForm__container {
    width: 100%;
}

.educationSpaceForm__head {
    display: flex;
    justify-content: space-between;

    padding-bottom: 20px;

    font-size: 24px;
    font-weight: 700;
}

.educationSpaceForm__label {
    display: flex;
    flex-direction: column;

    width: 360px;
    margin-bottom: 17px;
}

.educationSpaceForm__input {
    height: 30px;
    margin-top: 5px;

    border: 1px solid black;
}

// .educationSpaceTimeMesurment {
//   margin-top: 40px;
// }

// .educationSpaceTimeMesurment__select {
//   height: 30px;
//   margin-top: 6px;
//   margin-right: 10px;
//   margin-bottom: 5px;
// }

// .educationSpaceTimeMesurment__label {
//   display: flex;
// }

// .educationSpaceTimeMesurment_textContainer {
//   width: 294px;
//   margin-right: 107px;
// }

// .educationSpaceTimeMesurment__selectQuantity {
//   width: 40px;
// }

// .icon__greyCrossSkillDependency {
//   position: absolute;
//   right: -10px;

//   width: 20px;
//   height: 20px;

//   cursor: pointer;

//   fill: #c4c4c4;
// }

// .educationSubspaceCard__textContainer {
//   width: 100%;
// }

// .educationSubspaceTypeForm__switcher {
//   margin-top: 44px;
// }

// .educationButton {
//   margin-bottom: 25px;
// }

// .educationButton__bgButton {
//   margin-right: 20px;
// }

// .educationButton_width_fix {
//   width: 155px;
// }
