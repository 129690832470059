.roomSpace {
	margin-bottom: 24px ;
    align-items: flex-start;
}

.formRoom__input {
    input:required {
        box-shadow: #6BA3CE; // possible to add image
    }
    input:invalid {
        box-shadow: 0 0 5px 1px red;
    }

    input:focus:invalid {
        box-shadow: none;
    }
}