.moduleMeetingAndEvalPoint {
    padding: 0 24px;
}

.moduleMeetingAndEvalPoint__item {
    margin-bottom: 12px;
    padding: 16px;

    border: 1px solid #000;

    font-size: 14px;

    &:last-child {
        margin-bottom: 0;
    }
}