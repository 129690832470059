.navigation {
  display:flex;
  margin: 24px 0;
}

.navigation__arrow {
  width: 9px;
  height:15px;
  margin-left: 14px;
  margin-right: 14px;
  cursor: pointer;
}

.navigation__arrow_inactive {
  display:none;
}

.navigation__periodName {
  font-weight: bold;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}