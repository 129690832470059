.moduleSkills__container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 400px;
  width: 100%;

  overflow-y: auto;

  &::-webkit-scrollbar-track {
    border: 1px solid #f6f6f6;
    border-radius: 10px 30px 10px 10px;
  }
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 6px;
  }
}

.moduleSkills__title {
  font-size: 14px;
  cursor: default;
  margin-bottom: 24px;
}

.skillType {
  display: flex;
  flex-direction: row;

  margin-bottom: 10px;

  font-family: "Exo-2";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  cursor: pointer;
}

.skillType__open {
  margin-bottom: 12px;
}

.skillType__title {
  margin-left: 8px;
}

.skill {
  margin-left: 15px;
  font-size: 14px;
}

.skill__level {
  display: flex;
  justify-content: space-between;

  margin-left: 8px;
}

.skill__midle {
  display: flex;
  flex-direction: row;

  cursor: pointer;

  margin-left: 15px;
  margin-bottom: 15px;
}

.skill__child {
  cursor: default;

  margin-left: 25px;
  margin-bottom: 15px;
}

.skill__midle_name {
  margin-left: 8px;
  cursor: pointer;
}

.skill__name {
  margin-left: 8px;
  cursor: default;
}

.skill__list {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  margin-bottom: 22px;
  max-width: 270px;
  padding-right: 10px;
}

@media screen and (max-width: 800px) {
  .moduleSkills__container {
    height: 100%;
    width: 100%;
  }
}
