.skillTab {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin: 0 10px;
}

.skillTab__content_scroll {
  overflow-y: scroll;
  direction:  ltr;
  scrollbar-width: thin;
  scrollbar-color: #FFFFFF rgba(196, 196, 196, 0.2);
}

.skillItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}

.skillItem__name {
  font-size: 14px;
}

.skillProgress {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 45%;
}

//for other browsers
.skillTab__content_scroll::-webkit-scrollbar {
  width: 5px;
}

.skillTab__content_scroll::-webkit-scrollbar-track {
  background: #FFFFFF;
}

.skillTab__content_scroll::-webkit-scrollbar-thumb {
  width: 5px;
  height: 50px;
  background-color: rgba(196, 196, 196, 0.2);
  border-radius: 6px;
}
