.educationPeriodDates {
    padding: 24px 40px;
    position: relative;
    margin: 24px 0;
    background: #fff;
    border-radius: 15px;
    box-shadow: var(--default-shadow);
}

.educationPeriodDates__title {
    font-weight: bold;
    font-size: 24px;
    word-break: break-word;
}

.educationPeriodDates__buttonPanel {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.educationPeriodDates__button_active {
    width: 155px;
    padding: 10px 11px 10px 11px;
    margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
    .educationPeriodDates {
        padding: 12px;

        &__title {
            font-size: 18px;
        }

        &__buttonPanel {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        }
    }
}