.schedule__score {
    position: relative;

    background-color: rgba(255, 255, 255, 0.75);
    padding: 30px;
    margin-bottom: 40px;

    border-radius: 30px;
    box-shadow: var(--default-shadow);
}

.schedule__closeScore {
    position: absolute;
    right: 30px;
    top: 30px;

    width: 30px;
    height: 30px;

    cursor: pointer;
}

.schedule__scoreValue {
    margin-bottom: 10px;

    white-space: break-spaces;

    font-size: 18px;
}

.schedule__conflict_info {
    margin-bottom: 20px;

    font-size: 16px;
}

.schedule__scoreExplanation {
    white-space: pre-wrap;

    font-size: 14px;
}