.roomTypes {
	width: 100%;

    &__select {
        max-width: 347px;
    }
}

.roomTypes_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
	list-style-position: inside;
	list-style-type: none;
    gap: 4px;
}

.room-types_items {
    display: flex;
    align-items: center;
    gap: 12px;
}

.room-types_checkbox{
	display: flex;
    gap: 12px;
}

.roomTypes__title {
	font-weight: 700;
	font-size: 18px;
    margin: 0 0 12px 0;
}

.form {
    display: flex;
    flex-direction: column;
}