.dateSelector {
  display: flex;
	flex-direction: row;
}
.dateSelector__description {
  display: flex;
  width:300px;
  height:20px;
  margin-top:5px;
  margin-left: 12px;
}

.dateSelector__description_bold {
  font-weight: 700;
  margin-left: 5px;
}