.universityPage {
  margin: 0;

  &__address {
    margin-top: 49px;
  }
}
.universityPage__title {
  font-family: "Exo-2";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;

  margin-bottom: 40px;
}

.universityPage__container {
  width: 450px;
}

.universityPage__select {
  width: 150px;
}

.addNewBuild {
  display: flex;
  align-items: center;

  cursor: pointer;

  margin-top: 24px;
}

.addNewBuild__text {
  margin-left: 12px;
}
