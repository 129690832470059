.moduleHeader {
  display: flex;
  align-items: center;
  margin: 12px;
}

.moduleHeader__iconContainer {
  margin-right: 12px;
  height: 70px;
  width: 70px;
}

.moduleHeader__title {
  height: 54px;
  font-size: 18px;
  font-weight: 500;
  overflow-y: scroll;
  text-overflow: ellipsis;
  // display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.moduleHeader__title::-webkit-scrollbar {
  display: none;
}
