.parameter {
    display: flex;
    gap: 12px;
}

.parameter__box {
    border: 1px solid #000000;
}

.parameter__input {
    width: 38px;
    height: 21px;
    background-color: transparent;
    border: none;
    outline: none;
}

.parameter__description {

}
