.periodTitleEdit {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.periodTitleEdit__box {
    width:372px;
    height:30px;
    padding: 2px 5px 12px 5px;
    border: 1px solid #000000;
    margin-top: 3px;
}

.periodTitleEdit__input {
    width:360px;
    height:26px;
    background-color: transparent;
    border:none;
    outline: none;
    text-align: left;
}