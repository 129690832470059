.schedule__violation_container {
  border: none;
  box-shadow: var(--default-shadow);
  padding: 15px;
  margin-top: 10px;

  &:last-child {
    box-shadow: none;
  }
}

.schedule__violation_title {
  display: flex;
  justify-self: center;
  left: 0px;
  top: 0px;
  white-space: break-spaces;
  font-size: 16px;
  padding-bottom: 0px;
  cursor: pointer;
}

.schedule__violation_count {
  width: 40px;
}

.schedule__violation_arrow {
  width: 20px;
  height: 10px;
  position: absolute;
  right: 45px;
  margin-top: 3px;
}

.schedule__violation_arrow_reverse {
  transform: rotate(180deg);
  margin-top: 7px;
  margin-right: 4px;
}

.schedule__violation_meeting {
  margin-top: 1px;
  display: flex;
  flex-direction: row;
}

.schedule__violation_meeting_name {
  display: flex;
  cursor: pointer;
}

.violation__meeting_icon {
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  position: relative;
  bottom: 1px;
  left: 5px;
  cursor: pointer;
}

.schedule__violation_conflict {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 10px;
}

.schedule__violation_conflict_title {
  margin-top: 12px;
}

.schedule__violation_value {
  margin-top: 1px;
}
