.available {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
}
.available__title {
    margin-top: 10px;
}

.available__calendar {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

.available__addButton {
    margin-left: 25px;
}

.calendar__button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 280px;
    width: max-content;
    height: 30px;
    padding: 7px 5px;
    border-radius: 0;
    color: #000;
    margin: 0 7px 0 0;
}

.available__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 360px;
    height: 24px;
    background: var(--mock-color);
}

.available__deleteBtn {
    position: absolute;
    right: 17px;
    display: block;
    align-items: center;
    height: 24px;
    margin: 0;
    padding: 0;
    color: #c4c4c4;
}

.available__container {
    display: flex;
    flex-direction: column;
    max-width: 390px;
    position: relative;
    height: 24px;
}

.available_list {
    max-width: 390px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.available_listBlock {
  position: relative;

  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;

  width: 360px;
  margin: 3px 0;
}

.available_nameGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 360px;
  padding: 0 12px;

  background: var(--mock-color);
}

.available_btn {
  position: absolute;
  right: -11px;

  display: block;
  align-items: center;

  height: 24px;
  margin: 0;
  padding: 0;

  color: #c4c4c4;
}

.available_img {
  display: block;
  align-items: center;

  width: 22px;
  height: 22px;
}

.roomPage_form {
  display: flex;
}
