.module-assessment {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
}

.module-assessment__title {
    font-family: 'Manrope';
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
}

.module-assessment__switcher {
    margin-bottom: 15px;
}

.module-assessment__start-date_disabled,
.module-assessment__settings_disabled {
    opacity: .4;
    pointer-events: none;
    user-select: none;
}

.module-assessment__date-container {
    margin-top: 15px;
}

.module-assessment__calendar {
    margin-left: 10px;
    height: 30px;
    box-shadow: var(--default-shadow);
    border-radius: 10px;
    font-size: 14px;
    border: 0 solid;
    padding: 5px 10px 4px 11px;
    outline: none;
}

.module-assessment__start-date {
    margin-right: 40px;
}
