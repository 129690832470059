.headerSchedule {
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
}

.headerSchedule_timeOption {
    padding: 5px;
}

.headerSchedule_inputContainer {
    position: relative;
    display: flex;
    align-items: center;
    width: 204px;
}


.headerSchedule_inputMagnifier {
    position: absolute;
    right: 10px;
    width: 15px;
    height: 15px;
}

.printerButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border-radius: 15px;
    box-shadow: var(--default-shadow);
}

@media screen and (max-width: 1200px) {
    .headerSchedule {
        flex-direction: column;
        justify-content: center;
        margin: 60px 20px 0 20px;
    }
    .headerSchedule_inputContainer {
        display: none;
    }
    .printerButton {
        display: none;
    }
}

@media print {
    .headerSchedule_inputContainer {
        display: none;
    }

    .headerSchedule {
        justify-content: center;
        margin: 0 0 40px 0;
    }

    .printerButton {
        display: none;
    }
}
