.skillsTabs {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;

  &__title {
    font-family: "Exo-2";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    display: flex;
    margin-top: 14px;
  }
}

.tabsName {
  font-size: 14px;
  font-weight: 500;
  justify-content: flex-start;
  display: flex;
  margin: 0 10px 10px;
  align-items: flex-start;
  width: 100%;
}

.skillsTabs__content {
  width: 100%;
  margin-bottom: 24px;
  &:last-of-type {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 980px) {
  .skillsTabs__content {
    margin-left: 0;
  }
}

@media screen and (max-width: 1050px) and (min-width: 980px) {
  .skillsTabs__tabs {
    width: 90%;
    overflow-x: scroll;
    scrollbar-width: auto;
    scrollbar-color: rgba(196, 196, 196, 0.2) #ffffff;
  }

  //for other browsers
  .skillsTabs__tabs::-webkit-scrollbar {
    height: 5px;
  }

  .skillsTabs__tabs::-webkit-scrollbar-track {
    background: #ffffff;
  }

  .skillsTabs__tabs::-webkit-scrollbar-thumb {
    width: 5px;
    height: 5px;
    background-color: rgba(196, 196, 196, 0.2);
    border-radius: 20px;
  }

  .tabsName__NoActiveBorder {
    display: none;
  }

  .tabsName_activeBorder {
    width: 100%;
    height: 1px;
    background: var(--main-gradient);
  }
}

@media screen and (max-width: 500px) {
  .skillsTabs__tabs {
    width: 90%;
    overflow-x: scroll;
  }

  .skillsTabs__title {
    font-size: 16px;
    line-height: 20px;
  }

  //for Firefox
  .skillsTabs__tabs {
    scrollbar-width: thin;
    scrollbar-color: #ffffff rgba(196, 196, 196, 0.2);
  }

  //for other browsers
  .skillsTabs__tabs::-webkit-scrollbar {
    height: 5px;
  }

  .skillsTabs__tabs::-webkit-scrollbar-track {
    background: #ffffff;
  }

  .skillsTabs__tabs::-webkit-scrollbar-thumb {
    width: 5px;
    height: 5px;
    border: 1px solid rgba(196, 196, 196, 0.2);
    background-color: rgba(196, 196, 196, 0.2);
    border-radius: 20px;
  }

  .tabsName__NoActiveBorder {
    display: none;
  }

  .tabsName_activeBorder {
    width: 100%;
    height: 1px;
    background: var(--main-gradient);
  }

  .skillsTabs__content {
    width: 97%;
  }
}
